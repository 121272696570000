export const paths = {
  es: {
    FAQS: "preguntas-frecuentes/",
    termsAndConditions: "terminos-y-condiciones/",
    contactPage: "contacto/",
    privacy: "politica-de-privacidad/",
    about: "sobre-durcal/",
    pulsera: "",
    compra: "reloj/compra",
    folleto: "folleto"
  },
  it: {
    FAQS: "faqs/",
    termsAndConditions: "terms-and-conditions/",
    contactPage: "contact/",
    privacy: "privacy-policy/",
    about: "about-durcal/",
    pulsera: "",
    compra: "reloj/compra",
  },
}

export const LANGUAGES = {
  es: "es",
  it: "it",
}

export const checkLanguage = (location, intl) => {
  if (
    !location.pathname.includes(`/${LANGUAGES.it}/`) &&
    intl.locale === LANGUAGES.it
  ) {
    return `${LANGUAGES.it}/`
  }
  return ""
}

export const APP_STORES_LINK = {
  apple:
    "https://apps.apple.com/es/app/durcal-localizador-familiar/id622546357",
  google:
    "https://play.google.com/store/apps/details?id=app.alpify&hl=es&gl=US",
}
